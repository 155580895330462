import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About`}</h1>
    <p>{`Hi, I am Thomas Waldecker, a Software Developer based in Starnberg,
thats near Munich in Germany.`}</p>
    <p>{`I love working on Full-Stack projects where
I can support my customer with my ideas and creativity.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      